import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  Select,
  Button,
  Checkbox,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Flex,
  NumberInputField,
  Center,
  Textarea,
  Box,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";

import SubmissionSuccess from "./SubmissionSuccess";
import SubmissionFailure from "./SubmissionFailure";
import {
  DetailsFormData,
  FormSubmissionMeta,
  PostRequestBody,
} from "../types/types";
import SubHeader from "./SubHeader";

import Details from "../assets/details.svg";
import House from "../assets/house.svg";
import NextStep from "../assets/next_step.svg";

type Props = {
  campaignSuffix: string;
  campaignPrefix: string;
  loanOfficerName: string;
  surefireId: string;
  LoBrandingMobile: "*.png";
  simpleNexus: string;
  softpull: string;
  statesLicensedIn: {
    label: string;
    value: string;
  }[];
};

const DetailsForm = ({
  campaignPrefix,
  campaignSuffix,
  loanOfficerName,
  surefireId,
  LoBrandingMobile,
  simpleNexus,
  softpull,
  statesLicensedIn,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState<DetailsFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    zip: "",
    ficoEstimate: "",
    primaryResidence: false,
    notPrimaryResidence: false,
    otherUtil: false,
    otherMessage: "",
    firstTimeHomeBuyer: false,
    needsDPA: false,
    hasOwnedHome: false,
    movingStates: false,
    movingStatesMessage: "",
    veteran: false,
    otherDescription: false,
    poorCredit: false,
    limitedCash: false,
    researchPhase: false,
    readyToPurchase: false,
    consent1: false,
    surefireId: surefireId,
    source: `${campaignPrefix} ${campaignSuffix} (Marketing Lead)`,
    campaignDB: `${campaignPrefix}-${campaignSuffix}`,
    nextStep: location.pathname.includes("phone")
      ? "Wants More Info"
      : "Speak To LO",
  });

  const [attempted, setAttempted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [formSubmissionMeta, setFormSubmissionMeta] =
    useState<FormSubmissionMeta>({});
  const [loading, setLoading] = useState(false);

  const captureFormSubmissionMeta = async () => {
    let capturedSubmissionMeta: FormSubmissionMeta;
    try {
      const response = await fetch(
        "https://geolocation-db.com/json/66216340-906a-11ec-bf81-0b6a6efc741f"
      );
      capturedSubmissionMeta = await response.json();
    } catch (err) {
      console.log(err);
      capturedSubmissionMeta = { error: "Could not capture meta data." };
    }
    setFormSubmissionMeta(capturedSubmissionMeta);
    return capturedSubmissionMeta;
  };

  const handlePostRequest = async (postRequestBody: PostRequestBody) => {
    try {
      const response = await fetch(
        "https://us-central1-essex-leads.cloudfunctions.net/handleFormSubmission",
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(postRequestBody),
        }
      );
      if (response.ok) {
        setSuccessModalOpen(true);
      } else {
        setFailureModalOpen(true);
      }
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = async () => {
    setAttempted(true);
    const hasNotSelectedUtilization =
      formData.primaryResidence === false &&
      formData.notPrimaryResidence === false &&
      formData.otherUtil === false;
    const hasNotSelectedDescription =
      formData.firstTimeHomeBuyer === false &&
      formData.needsDPA === false &&
      formData.hasOwnedHome === false &&
      formData.movingStates === false &&
      formData.veteran === false &&
      formData.otherDescription === false;
    const hasNotSelectedSituation =
      formData.poorCredit === false &&
      formData.limitedCash === false &&
      formData.researchPhase === false &&
      formData.readyToPurchase === false;

    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.phoneNumber === "" ||
      formData.state === "" ||
      formData.zip.length < 5 ||
      hasNotSelectedUtilization ||
      hasNotSelectedDescription ||
      hasNotSelectedSituation ||
      formData.consent1 === false ||
      (formData.ficoEstimate !== "" &&
        (Number.parseInt(formData.ficoEstimate!) < 300 ||
          Number.parseInt(formData.ficoEstimate!) > 850)) ||
      captchaCompleted === false
    )
      return;

    setLoading(true);
    const submissionMeta = await captureFormSubmissionMeta();
    await handlePostRequest({
      ...formData,
      submissionMeta,
    });

    navigate(`/${campaignPrefix}-${campaignSuffix}/submission-success`);
    setLoading(false);
  };

  const renderStatesLicensedIn = () => {
    return statesLicensedIn.map((state) => {
      return <option value={state.value}>{state.label}</option>;
    });
  };

  return (
    <>
      <VStack w="full" h="full" p={10} spacing={10} bgColor="#a3a2a5" id="form">
        <SubHeader title="Your Details" icon={Details} adjustmentLeft={2}>
          Please enter your information below so we can get a better idea of
          what loan(s) you may qualify for.
        </SubHeader>

        <SimpleGrid columns={[2, 2, 3]} columnGap={3} rowGap="2rem">
          <GridItem colSpan={1}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.firstName === ""}
            >
              <FormLabel color="#fff" fontSize="xl">
                First Name
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                name="firstName"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              {attempted && formData.firstName === "" && (
                <FormErrorMessage>First name is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.lastName === ""}
            >
              <FormLabel color="#fff" fontSize="xl">
                Last Name
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                name="lastName"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              {attempted && formData.lastName === "" && (
                <FormErrorMessage>Last name is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={[2, 1, 1]}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.email === ""}
            >
              <FormLabel color="#fff" fontSize="xl">
                Email
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              {attempted && formData.email === "" && (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={[2, 1]}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.phoneNumber === ""}
            >
              <FormLabel color="#fff" fontSize={"xl"}>
                Phone Number
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              {attempted && formData.phoneNumber === "" && (
                <FormErrorMessage>Phone Number is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel color="#fff" fontSize="xl">
                City
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                name="city"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.state === ""}
            >
              <FormLabel color="#fff" fontSize="xl">
                State
              </FormLabel>
              <Select
                bgColor="#fff"
                borderColor="#15225d"
                name="state"
                value={formData.state}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              >
                <option value="">---</option>
                {renderStatesLicensedIn()}
              </Select>
              {attempted && formData.state === "" && (
                <FormErrorMessage>State is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={1} alignSelf={"flex-end"}>
            <FormControl
              isRequired
              isInvalid={attempted && formData.zip.length < 5}
            >
              <FormLabel color="#fff" fontSize="xl">
                ZIP
              </FormLabel>
              <Input
                bgColor="#fff"
                borderColor="#15225d"
                name="zip"
                value={formData.zip}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
              />
              {attempted && formData.zip.length < 5 && (
                <FormErrorMessage>
                  ZIP must be five digits long.
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={[2, 1]}>
            <FormControl
              isInvalid={
                attempted &&
                (Number.parseInt(formData.ficoEstimate!) < 300 ||
                  Number.parseInt(formData.ficoEstimate!) > 850)
              }
            >
              <FormLabel color="#fff" fontSize="xl">
                Self Reported FICO
              </FormLabel>
              <NumberInput min={300} max={850} clampValueOnBlur={false}>
                <NumberInputField
                  bgColor="#fff"
                  borderColor="#15225d"
                  name="ficoEstimate"
                  value={formData.ficoEstimate}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </NumberInput>
              {attempted &&
                (Number.parseInt(formData.ficoEstimate!) < 300 ||
                  Number.parseInt(formData.ficoEstimate!) > 850) && (
                  <FormErrorMessage>Invalid FICO</FormErrorMessage>
                )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]}>
            <SubHeader
              title="Home Loans"
              icon={House}
              adjustmentLeft={6}
              adjustmentTop={5}
            >
              We offer many types of home loans. Fill out these details before
              so we know what you are looking for.
            </SubHeader>
          </GridItem>
          <GridItem colSpan={[2, 2, 3]}>
            <VStack align="stretch">
              <FormControl
                isRequired
                isInvalid={
                  attempted &&
                  formData.primaryResidence === false &&
                  formData.notPrimaryResidence === false &&
                  formData.otherUtil === false
                }
              >
                <FormLabel fontSize="xl" color="#fff" fontWeight="bold">
                  Select the best option(s) below as to how this home loan will
                  be utilized.
                </FormLabel>
                <VStack align="stretch">
                  <Checkbox
                    name="primaryResidence"
                    color="#fff"
                    size="lg"
                    isChecked={formData.primaryResidence}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    This will be my primary residence
                  </Checkbox>
                  <Checkbox
                    name="notPrimaryResidence"
                    color="#fff"
                    size="lg"
                    isChecked={formData.notPrimaryResidence}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    This will NOT be my primary residence
                  </Checkbox>
                  <Checkbox
                    name="otherUtil"
                    color="#fff"
                    size="lg"
                    isChecked={formData.otherUtil}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    Other, please specify
                  </Checkbox>
                  <Textarea
                    maxWidth={"35rem"}
                    bgColor="#fff"
                    borderColor="#15225d"
                    name="otherMessage"
                    value={formData.otherMessage}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                        otherUtil: true,
                      });
                    }}
                  />
                  {attempted &&
                    formData.primaryResidence === false &&
                    formData.notPrimaryResidence === false &&
                    formData.otherUtil === false && (
                      <FormErrorMessage>
                        Must select at least one option.
                      </FormErrorMessage>
                    )}
                </VStack>
              </FormControl>
            </VStack>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]}>
            <VStack align="stretch">
              <FormControl
                isRequired
                isInvalid={
                  attempted &&
                  formData.firstTimeHomeBuyer === false &&
                  formData.needsDPA === false &&
                  formData.hasOwnedHome === false &&
                  formData.movingStates === false &&
                  formData.veteran === false &&
                  formData.otherDescription === false
                }
              >
                <FormLabel fontSize="xl" color="#fff" fontWeight="bold">
                  Choose the option(s) below that best describe you.
                </FormLabel>
                <VStack align="stretch">
                  <Checkbox
                    name="firstTimeHomeBuyer"
                    color="#fff"
                    size="lg"
                    isChecked={formData.firstTimeHomeBuyer}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    I am a first time home buyer
                  </Checkbox>
                  <Checkbox
                    name="needsDPA"
                    color="#fff"
                    size="lg"
                    isChecked={formData.needsDPA}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    I need down payment assistance help
                  </Checkbox>
                  <Checkbox
                    name="hasOwnedHome"
                    color="#fff"
                    size="lg"
                    isChecked={formData.hasOwnedHome}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    I have owned a home before
                  </Checkbox>
                  <Checkbox
                    name="movingStates"
                    color="#fff"
                    size="lg"
                    isChecked={formData.movingStates}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    I am moving states, please specify:
                  </Checkbox>
                  <Box>
                    <Input
                      maxWidth={"35rem"}
                      bgColor="#fff"
                      borderColor="#15225d"
                      name="movingStatesMessage"
                      value={formData.movingStatesMessage}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                          movingStates: true,
                        });
                      }}
                    />
                  </Box>
                  <Checkbox
                    name="veteran"
                    color="#fff"
                    size="lg"
                    isChecked={formData.veteran}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    I am a Veteran
                  </Checkbox>
                  <Checkbox
                    name="otherDescription"
                    color="#fff"
                    size="lg"
                    isChecked={formData.otherDescription}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    Other
                  </Checkbox>
                </VStack>
                {attempted &&
                  formData.firstTimeHomeBuyer === false &&
                  formData.needsDPA === false &&
                  formData.hasOwnedHome === false &&
                  formData.movingStates === false &&
                  formData.veteran === false &&
                  formData.otherDescription === false && (
                    <FormErrorMessage>
                      Must select at least one option.
                    </FormErrorMessage>
                  )}
              </FormControl>
            </VStack>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]}>
            <VStack align="stretch">
              <FormControl
                isRequired
                isInvalid={
                  attempted &&
                  formData.poorCredit === false &&
                  formData.limitedCash === false &&
                  formData.researchPhase === false &&
                  formData.readyToPurchase === false
                }
              >
                <FormLabel fontSize="xl" color="#fff" fontWeight="bold">
                  Select the option(s) that best describe your situation.
                </FormLabel>
                <VStack align="stretch" spacing={["2rem", 2]}>
                  <Checkbox
                    name="poorCredit"
                    color="#fff"
                    size="lg"
                    isChecked={formData.poorCredit}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    You have a poor credit score and you have been working to
                    reestablish your credit standing
                  </Checkbox>
                  <Checkbox
                    name="limitedCash"
                    color="#fff"
                    size="lg"
                    isChecked={formData.limitedCash}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    You have limited cash resources and need a lower down
                    payment
                  </Checkbox>
                  <Checkbox
                    name="researchPhase"
                    color="#fff"
                    size="lg"
                    isChecked={formData.researchPhase}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      })
                    }
                  >
                    You are in the research phase and not ready to commit to a
                    loan just yet
                  </Checkbox>
                  <Checkbox
                    name="readyToPurchase"
                    color="#fff"
                    size="lg"
                    isChecked={formData.readyToPurchase}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    You are ready to purchase a home within the next few months
                    and are needing a home loan
                  </Checkbox>
                  {attempted &&
                    formData.poorCredit === false &&
                    formData.limitedCash === false &&
                    formData.researchPhase === false &&
                    formData.readyToPurchase === false && (
                      <FormErrorMessage>
                        Must select at least one option.
                      </FormErrorMessage>
                    )}
                </VStack>
              </FormControl>
            </VStack>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]}>
            <SubHeader title="Next Steps" icon={NextStep}>
              Select an option below for what you would like to do as your next
              step.
            </SubHeader>
          </GridItem>

          <GridItem mb={5} colSpan={[2, 2, 3]}>
            <FormControl>
              <RadioGroup
                onChange={(val) =>
                  setFormData({
                    ...formData,
                    nextStep: val as
                      | "Speak To LO"
                      | "Apply"
                      | "Credit Pull"
                      | "Wants More Info",
                  })
                }
                value={formData.nextStep}
              >
                <Flex
                  direction={["column", "column", "row"]}
                  gap={8}
                  flexWrap="wrap"
                >
                  {location.pathname.includes("phone") ? (
                    <Radio value="Wants More Info">
                      <Text color="#fff" fontSize="xl">
                        Wants More Info
                      </Text>
                    </Radio>
                  ) : (
                    <Radio value="Speak To LO">
                      <Text color="#fff" fontSize="xl">
                        Speak with {loanOfficerName}
                      </Text>
                    </Radio>
                  )}
                  <Radio value="Apply">
                    <Text color="#fff" fontSize="xl">
                      Apply Now
                    </Text>
                  </Radio>
                  <Radio value="Credit Pull">
                    <Text color="#fff" fontSize="xl">
                      Get Pre-Qualified
                    </Text>
                  </Radio>
                </Flex>
              </RadioGroup>
            </FormControl>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]} mb="2vh">
            <FormControl
              isRequired
              isInvalid={attempted && formData.consent1 === false}
            >
              <Checkbox
                color="#fff"
                fontSize="xl"
                spacing={"1.5rem"}
                name="consent1"
                value={formData.consent1.toString()}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.checked,
                  })
                }
              >
                By clicking Submit, you are granting Essex Mortgage consent to
                contact you regarding potential mortgage loan products at the
                mobile phone number listed above, via call and/or SMS text
                message. Please note, depending on your mobile service plan,
                message and data rates may apply.
              </Checkbox>
              {attempted && formData.consent1 === false && (
                <FormErrorMessage>Consent is required.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>

          <GridItem colSpan={[2, 2, 3]}>
            <FormControl
              isRequired
              isInvalid={attempted && captchaCompleted === false}
            >
              <ReCAPTCHA
                sitekey="6Lem4OseAAAAAPM_g_N1KX_dfFGiH-icK5mIBDq6"
                onChange={() => setCaptchaCompleted(true)}
              />
              {attempted && captchaCompleted === false && (
                <FormErrorMessage>Please complete reCAPTCHA.</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem colSpan={[2, 2, 3]}>
            <Center>
              <Button
                p="35px"
                borderRadius="0"
                disabled={submitted}
                isLoading={loading}
                loadingText="Submitting..."
                bg="#15225d"
                color="white"
                _hover={{
                  background: "#6b6c96",
                  color: "white",
                }}
                type="submit"
                size="lg"
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </Center>
          </GridItem>
        </SimpleGrid>
      </VStack>
      <Routes>
        <Route
          path="/submission-success"
          element={
            <SubmissionSuccess
              loanOfficerName={loanOfficerName}
              successModalOpen={successModalOpen}
              setSuccessModalOpen={setSuccessModalOpen}
              formSubmissionMeta={formSubmissionMeta}
              values={formData}
              LoBrandingMobile={LoBrandingMobile}
              simpleNexus={simpleNexus}
              softpull={softpull}
            />
          }
        />
      </Routes>

      <SubmissionFailure
        failureModalOpen={failureModalOpen}
        setFailureModalOpen={setFailureModalOpen}
      />
    </>
  );
};

export default DetailsForm;
