import React from "react";
import {Flex, Image, Tabs, TabList, Tab, Heading} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import EssexLogo from "../../assets/Essex_Color.png";

type Props = {};

const AdminHeader = (props: Props) => {
  return (
    <Flex bg="white" minW="100vw">
      <Image src={EssexLogo} maxW="200px" mr="2vw" />
      <Tabs>
        <TabList height="100%">
          <Tab>
            <Link to="submissions">
              <Heading size="sm">Submissions</Heading>
            </Link>
          </Tab>
          {/* <Tab>
            <Link to="analytics">
              <Heading size="sm">Analytics</Heading>
            </Link>
          </Tab> */}
        </TabList>
      </Tabs>
    </Flex>
  );
};

export default AdminHeader;
