import TCollinsHeadshot from "./assets/tcollins_headshot.png";
import TCollinsBranding from "./assets/tcollins.png";
import TCollinsBrandingMobile from "./assets/tcollins_mobile.png";
import BRobbinsHeadshot from "./assets/brobbins_headshot.png";
import BRobbinsBranding from "./assets/brobbins-bmichna.png";
import BRobbinsBrandingMobile from "./assets/brobbins-bmichna_mobile.png";

type ComputedRoute = {
  campaignPrefix: string;
  campaignSuffix: string;
  loanOfficerName: string;
  surefireId: string;
  LoBranding: "*.png";
  LoBrandingMobile: "*.png";
  simpleNexus: string;
  softpull: string;
  statesLicensedIn: { label: string; value: string }[];
  bio: string;
  headshot: "*.png";
  reviews: { author: string; review: string }[];
  cell: string;
};

// const defaultCampaignSuffixes = [
//   "gad-phone",
//   "gad1",
//   "gad2",
//   "gad3",
//   "gad4",
//   "gad5",
//   "gad6",
//   "gad7",
//   "gad8",
//   "gad9",
//   "gad10",
//   "gad11",
//   "gad12",
//   "fbad1",
//   "fbad2",
//   "fbad3",
//   "fbad4",
//   "fbad5",
//   "fbad6",
//   "fbad7",
//   "fbad8",
//   "fbad9",
//   "fbad10",
//   "fbad11",
//   "fbad12",
// ];

const routes = [
  {
    campaignPrefix: "tcollins",
    campaignSuffixes: ["gad8", "fbad8"],
    loanOfficerName: "Todd",
    surefireId: "0wbOIw",
    LoBranding: TCollinsBranding,
    LoBrandingMobile: TCollinsBrandingMobile,
    simpleNexus: "http://apply.essexmortgage.com/p2dyg",
    softpull: "https://essexhomeloans.com/tcollins-softpull",
    statesLicensedIn: [
      { label: "California", value: "CA" },
      { label: "South Carolina", value: "SC" },
    ],
    bio: "Todd has been helping the community with their home loan needs for more than 18 Years. He takes pride in helping borrowers and their families find the best financing available to fit their individual needs. He guides his clients through their loan options and helps them choose the correct program to achieve their home ownership dreams. Whether you are a first-time home buyer or a seasoned investor, having an advocate on your side who is looking out for your best interest will help you to accomplish your goals.",
    headshot: TCollinsHeadshot,
    reviews: [
      {
        author: "Jason, W., CA",
        review: "The whole experience was stress free and easy. Thanks again!",
      },
      {
        author: "Jill, R., CA",
        review:
          "Todd is an amazing lender. He talks you through the whole experience and is always available to offer advice and to answer questions. He and his team are amazing. I highly recommend this lender.",
      },
      {
        author: "Jennifer, H., CA",
        review:
          "I would highly recommend Todd. Getting prepared to purchase a house is never an easy process but with the help of all of the professionals in his office makes the loan process less stressful. Thank you so much!",
      },
    ],
    cell: "(916) 813-1336",
  },
  {
    campaignPrefix: "brobbins-bmichna",
    campaignSuffixes: ["gad1", "fbad1"],
    loanOfficerName: "Brad",
    surefireId: "0YVlhw",
    LoBranding: BRobbinsBranding,
    LoBrandingMobile: BRobbinsBrandingMobile,
    simpleNexus: "http://apply.essexmortgage.com/7wksv",
    softpull: "https://essexhomeloans.com/brobbins-softpull",
    statesLicensedIn: [
      { label: "California", value: "CA" },
      { label: "Texas", value: "TX" },
    ],
    bio: "I was born and raised in Central Texas. I have always had a passion for business, real estate and helping others! I am an educator and coach at heart! After a stint with Watters International Realty as the Listing Specialist, I decided it was time to pursue my Texas Real Estate Brokers License. I was able to obtain my Texas Real Estate Brokerage License in 4 short years. I have had the honor to learn from some of the best mentors in the business!\nA few years ago I knew committed to learning more about the mortgage industry to better serve my clients. Now as a Licensed Mortgage Loan Originator in Texas I have all the knowledge and expertise to put my clients in a position to win! My wife Mallory and I have a son (Jaxson) and a daughter (Braylei) and currently live in the Austin area. In my spare time, I enjoy the outdoors and enjoy traveling with family. I use new-age marketing ideas and cutting edge technology to produce desired results! My goal for each client is to provide EDUCATION about the market and EMPOWER the client to make an informed decision(s) while providing EXCELLENT customer service!",
    headshot: BRobbinsHeadshot,
    reviews: [
      {
        author: "Review Author",
        review:
          "Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body",
      },
      {
        author: "Review Author",
        review:
          "Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body",
      },
      {
        author: "Review Author",
        review:
          "Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body Review body",
      },
    ],
    cell: "(512) 269-5278",
  },
];

const createRoutes = () => {
  const computedRoutes: Array<ComputedRoute> = [];

  for (const route of routes) {
    for (const suffix of route.campaignSuffixes) {
      computedRoutes.push({
        campaignPrefix: route.campaignPrefix,
        campaignSuffix: suffix,
        loanOfficerName: route.loanOfficerName,
        surefireId: route.surefireId,
        LoBranding: route.LoBranding,
        LoBrandingMobile: route.LoBrandingMobile,
        simpleNexus: route.simpleNexus,
        softpull: route.softpull,
        statesLicensedIn: route.statesLicensedIn,
        bio: route.bio,
        headshot: route.headshot,
        reviews: route.reviews,
        cell: route.cell,
      });
    }
  }

  return computedRoutes;
};

export const exportedRoutes: Array<ComputedRoute> = createRoutes();
