import React, {useState, useEffect} from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Spinner,
  Container,
  Text,
  Select,
  VStack,
  Flex,
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Box,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import {useTable, useSortBy, usePagination} from "react-table";

import {SubmissionTableRow} from "../../types/types";
import dayjs, {Dayjs} from "dayjs";

type Props = {
  campaign: string;
  timeframeStart: Dayjs;
  timeframeEnd: Dayjs;
};

const CampaignSubmissionsTable = ({
  campaign,
  timeframeStart,
  timeframeEnd,
}: Props) => {
  const [submissions, setSubmissions] = useState<SubmissionTableRow[]>([]);
  const [loading, setLoading] = useState(true);

  const mostFrequent = (words: string[]): {value: string; count: number} => {
    const freq = new Map<string, number>();

    for (let word of words) {
      if (word !== null) {
        if (!freq.has(word)) {
          freq.set(word, 1);
        } else {
          freq.set(word, freq.get(word)! + 1);
        }
      }
    }

    const mostFrequent = [...freq.keys()]
      .sort((a, b) => {
        const freq1 = freq.get(a);
        const freq2 = freq.get(b);

        if (freq1 === freq2) {
          return a > b ? 1 : -1;
        } else {
          return freq2! - freq1!;
        }
      })
      .slice(0, 1);

    if (mostFrequent.length > 0) {
      return {value: mostFrequent[0], count: freq.get(mostFrequent[0])!};
    }

    return {value: "", count: 0};
  };

  useEffect(() => {
    const fetchCampaignSubmissions = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essex-leads.cloudfunctions.net/getCampaignSubmissions?campaignName=${campaign}`,
        );

        const json: SubmissionTableRow[] = await response.json();
        // if (json.length > 0) {
        //   for (let i = 0; i < 5; i++) {
        //     json.push(json[0]);
        //   }
        // }

        const filteredByDate = json.filter(
          (row) =>
            dayjs(row.dateTime).isAfter(timeframeStart) &&
            dayjs(row.dateTime).isBefore(timeframeEnd),
        );

        setSubmissions(filteredByDate);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchCampaignSubmissions();
  }, [campaign, timeframeStart, timeframeEnd]);

  const data = React.useMemo(() => submissions, [submissions]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Submitted",
        accessor: "dateTime" as const,
      },
      {
        Header: "First Name",
        accessor: "firstName" as const,
      },
      {
        Header: "Last Name",
        accessor: "lastName" as const,
      },
      {
        Header: "Fico Estimate",
        accessor: "ficoEstimate" as const,
      },
      {
        Header: "Email",
        accessor: "email" as const,
      },
      {
        Header: "Phone",
        accessor: "phoneNumber" as const,
      },
      {
        Header: "User Provided",
        columns: [
          {
            Header: "City",
            accessor: "city" as const,
          },
          {
            Header: "State",
            accessor: "state" as const,
          },
          {
            Header: "ZIP",
            accessor: "zip" as const,
          },
        ],
      },

      {
        Header: "IP Captured",
        columns: [
          {
            Header: "City",
            accessor: "submittedCity" as const,
          },
          {
            Header: "State",
            accessor: "submittedState" as const,
          },
          {
            Header: "ZIP",
            accessor: "submittedZip" as const,
          },
        ],
      },
      {
        Header: "Surefire Source Tag",
        accessor: "source" as const,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "dateTime",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  const topUserZip = mostFrequent(submissions.map((sub) => sub.zip));
  const topIPZip = mostFrequent(submissions.map((sub) => sub.submittedZip));

  return (
    <Box
      p={5}
      gap={10}
      bg="white"
      borderRadius="10px"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;"
    >
      {loading ? (
        <Spinner size="lg" />
      ) : (
        <>
          <Flex gap="20px">
            <Stat
              border="1px solid #e2e8f0"
              borderRadius="10px"
              my="3"
              p="3"
              maxW="250px"
            >
              <StatLabel>Total Submissions</StatLabel>
              <StatNumber>{submissions.length}</StatNumber>
            </Stat>
            <Stat
              border="1px solid #e2e8f0"
              borderRadius="10px"
              my="3"
              p="3"
              maxW="250px"
            >
              <StatLabel>Top User Provided ZIP</StatLabel>
              <StatNumber>{topUserZip.value}</StatNumber>
              <StatHelpText>
                {`${topUserZip.count} submissions
                ${
                  Number.isNaN((topUserZip.count / submissions.length) * 100)
                    ? ``
                    : `(${Math.round(
                        (topUserZip.count / submissions.length) * 100,
                      )}%)`
                }`}
              </StatHelpText>
            </Stat>
            <Stat
              border="1px solid #e2e8f0"
              borderRadius="10px"
              my="3"
              p="3"
              maxW="250px"
            >
              <StatLabel>Top IP Capture ZIP</StatLabel>
              <StatNumber>{topIPZip.value}</StatNumber>
              <StatHelpText>
                {`${topIPZip.count} submissions
                ${
                  Number.isNaN((topIPZip.count / submissions.length) * 100)
                    ? ``
                    : `(${Math.round(
                        (topIPZip.count / submissions.length) * 100,
                      )}%)`
                }`}
              </StatHelpText>
            </Stat>
          </Flex>
          <Container maxW="100vw">
            <Table
              {...getTableProps()}
              size="sm"
              colorScheme={"linkedin"}
              variant={"striped"}
            >
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render("Header")}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>

            <VStack mt={4}>
              <Text>
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </Text>
              <Flex gap={2}>
                <IconButton
                  aria-label="Go to first page"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  icon={<ArrowLeftIcon />}
                />
                <IconButton
                  aria-label="Go back one page"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  icon={<ArrowBackIcon />}
                />
                <IconButton
                  aria-label="Go forward one page"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  icon={<ArrowForwardIcon />}
                />
                <IconButton
                  aria-label="Go to last page"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  icon={<ArrowRightIcon />}
                />
              </Flex>

              <Select
                w={"10vw"}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </VStack>
          </Container>
        </>
      )}
    </Box>
  );
};

export default CampaignSubmissionsTable;
