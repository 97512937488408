import React from "react";
import { VStack, Image, Text, Divider } from "@chakra-ui/react";

import Banner from "../assets/banner.png";
import EssexRound from "../assets/essex_round.png";

const Header = () => {
  return (
    <VStack bg="gray.100" w="100%" spacing={0}>
      <Image
        src={Banner}
        objectFit="cover"
        w={["100vw"]}
        h={["20vh", "30vh", "45vh"]}
        alt="Essex Logo"
      />
      <Image
        src={EssexRound}
        position="absolute"
        w={["125px", "155px", "255px"]}
        h={["125px", "155px", "255px"]}
        alt="Essex Logo"
        top={["10px", "50px"]}
      />
      <VStack bg="#15225d" p={"3%"} spacing="7">
        <Text
          color="#fff"
          fontSize={["10px", "1rem"]}
          textAlign="center"
          lineHeight={1.5}
        >
          We are so glad to see you're interested in a loan from Essex Mortgage!
          The loan process can be confusing for first time homebuyers or
          long-time renters, but we're excited to help make this process
          seamless and stress-free! At Essex Mortgage, we strive to hear your
          needs and then meet those needs exactly where you're at. We are here
          for YOU!
        </Text>
        <Divider w="15vw" />
        <Text
          color="#fff"
          fontSize={["10px", "1rem"]}
          textAlign="center"
          lineHeight={1.5}
          fontWeight="bold"
        >
          Please fill out the details below and one of our trusted loan officers
          will be in touch with you shortly.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Header;
