import React from "react";
import { Container, Img, VStack, Flex } from "@chakra-ui/react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DetailsForm from "../../components/DetailsForm";
import Navbar from "../../components/Navbar";
import DownArrow from "../../assets/down-arrow.svg";
import AboutEssex from "../../components/AboutEssex";
import AboutLoanOfficer from "../../components/AboutLoanOfficer";
import Reviews from "../../components/Reviews";

type Props = {
  loanOfficerName: string;
  campaignPrefix: string;
  campaignSuffix: string;
  surefireId: string;
  LoBranding: "*.png";
  LoBrandingMobile: "*.png";
  simpleNexus: string;
  softpull: string;
  statesLicensedIn: {
    label: string;
    value: string;
  }[];
  bio: string;
  headshot: "*.png";
  reviews: {
    author: string;
    review: string;
  }[];
  cell: string;
};

const Campaign = ({
  loanOfficerName,
  campaignPrefix,
  campaignSuffix,
  surefireId,
  LoBranding,
  LoBrandingMobile,
  simpleNexus,
  softpull,
  statesLicensedIn,
  bio,
  headshot,
  reviews,
  cell,
}: Props) => {
  return (
    <Container maxW="container.xl" p={0}>
      <VStack spacing={0}>
        <Header />
        <Navbar loanOfficerName={loanOfficerName} cell={cell} />
        <DetailsForm
          loanOfficerName={loanOfficerName}
          campaignPrefix={campaignPrefix}
          campaignSuffix={campaignSuffix}
          surefireId={surefireId}
          LoBrandingMobile={LoBrandingMobile}
          simpleNexus={simpleNexus}
          softpull={softpull}
          statesLicensedIn={statesLicensedIn}
        />
        <Flex w="100%" h="5vh" bg="#15225d" justifyContent="center">
          <Img src={DownArrow} w="40px" />
        </Flex>
        <AboutEssex />
        <AboutLoanOfficer
          loanOfficerName={loanOfficerName}
          bio={bio}
          headshot={headshot}
        />
        <Reviews loanOfficerName={loanOfficerName} reviews={reviews} />
        <Footer LoBranding={LoBranding} LoBrandingMobile={LoBrandingMobile} />
      </VStack>
    </Container>
  );
};

export default Campaign;
