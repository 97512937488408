import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

type Props = { loanOfficerName: string; cell: string };

const Navbar = ({ loanOfficerName, cell }: Props) => {
  return (
    <Box w="90%">
      <Flex
        justifyContent={["space-around"]}
        alignItems="center"
        w="100%"
        m="2vh 0"
        flexDirection={["column", "row"]}
        flexWrap={["wrap", "wrap", "nowrap"]}
        gap="10px"
        color="#fff"
        textDecoration="underline"
      >
        <Box bgColor="#15225d" p="10px 20px" w="300px" textAlign="center">
          <HashLink to="#form" smooth>
            Fill Out the Form Below
          </HashLink>
        </Box>
        <Box bgColor="#15225d" p="10px 20px" w="300px" textAlign="center">
          <HashLink smooth to="#about-essex">
            About Essex Mortgage
          </HashLink>
        </Box>
        <Box bgColor="#15225d" p="10px 20px" w="300px" textAlign="center">
          <HashLink smooth to="#about-loan-officer">
            About {loanOfficerName}
          </HashLink>
        </Box>
        <Box bgColor="#15225d" p="10px 20px" w="300px" textAlign="center">
          <a href={`tel:${cell}`}>Call {loanOfficerName}</a>
        </Box>
        <Box bgColor="#15225d" p="10px 20px" w="300px" textAlign="center">
          <HashLink smooth to="#reviews">
            Reviews
          </HashLink>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
