import React, {useState, useEffect} from "react";
import {
  Container,
  Heading,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Select,
  Box,
  Flex,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import CampaignSubmissionsTable from "./CampaignSubmissionsTable";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./CalendarAdjustments.css";

type Props = {};

const CampaignSubmissions = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [campaignTitles, setCampaignTitles] = useState<string[]>([]);
  const [campaignGroups, setCampaignGroups] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [timeframeStart, setTimeframeStart] = useState(
    dayjs().subtract(14, "days"),
  );
  const [timeframeEnd, setTimeframeEnd] = useState(dayjs());

  useEffect(() => {
    const fetchCampaignTitles = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://us-central1-essex-leads.cloudfunctions.net/getAllCollectionNames",
        );

        let json: string[] = await response.json();

        const campaignGroups = [
          ...new Set(
            json.map((campaignTitle) =>
              campaignTitle.replace(/(-gad\w+|-fbad\w+)/g, ""),
            ),
          ),
        ];

        json = json.sort((a, b) => {
          const aRegexResult = a.match(/(\d+)/g);
          let aInt = 0;
          if (aRegexResult !== null) {
            aInt = Number.parseInt(aRegexResult[0]);
          }

          const bRegexResult = b.match(/(\d+)/g);
          let bInt = 0;
          if (bRegexResult !== null) {
            bInt = Number.parseInt(bRegexResult[0]);
          }

          return aInt - bInt;
        });

        setCampaignTitles(json);
        setSelectedCampaign(json[0]);
        setCampaignGroups(campaignGroups);
        setSelectedGroup(campaignGroups[0]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchCampaignTitles();
  }, []);

  const handleGroupChange = (group: string) => {
    setSelectedGroup(group);
    setSelectedCampaign(
      campaignTitles.filter((campaign) => campaign.includes(selectedGroup))[0],
    );
  };

  const renderGroupTabs = () => {
    return campaignGroups.map((group) => (
      <Tab key={group} onClick={() => handleGroupChange(group)}>
        {group}
      </Tab>
    ));
  };

  const renderTitleOptions = () => {
    return campaignTitles
      .filter((campaign) => campaign.includes(selectedGroup))
      .map((campaign) => <option key={campaign}>{campaign}</option>);
  };

  return (
    <Container my={5} minW="100vw" minH="100vh">
      {loading ? (
        <Spinner size="lg" />
      ) : (
        <>
          <Flex
            mb={5}
            p={5}
            gap={10}
            bg="white"
            borderRadius="10px"
            boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;"
          >
            <Box>
              <Heading size="md" mb={3}>
                Campaign Groups
              </Heading>
              <Tabs variant={"soft-rounded"}>
                <TabList>{renderGroupTabs()}</TabList>
              </Tabs>
            </Box>
            <Box>
              <Heading size="md" mb={3}>
                Individual Campaigns
              </Heading>
              <Select
                value={selectedCampaign}
                onChange={(e) => setSelectedCampaign(e.target.value)}
              >
                {renderTitleOptions()}
              </Select>
            </Box>
            <Box>
              <Heading size="md" mb={3}>
                Start
              </Heading>
              <ReactDatePicker
                className="border-adjustments"
                selected={timeframeStart.toDate()}
                onChange={(date) => setTimeframeStart(dayjs(date))}
                selectsStart
                startDate={timeframeStart.toDate()}
                endDate={timeframeEnd.toDate()}
                onChangeRaw={(e) => e.preventDefault()}
              />
            </Box>
            <Box>
              <Heading size="md" mb={3}>
                End
              </Heading>
              <ReactDatePicker
                className="border-adjustments"
                selected={timeframeEnd.toDate()}
                onChange={(date) => setTimeframeEnd(dayjs(date))}
                selectsEnd
                endDate={timeframeEnd.toDate()}
                startDate={timeframeStart.toDate()}
                minDate={timeframeStart.toDate()}
                onChangeRaw={(e) => e.preventDefault()}
              />
            </Box>
          </Flex>

          <CampaignSubmissionsTable
            campaign={selectedCampaign}
            timeframeStart={timeframeStart}
            timeframeEnd={timeframeEnd}
          />
        </>
      )}
    </Container>
  );
};

export default CampaignSubmissions;
