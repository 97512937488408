import React from "react";

type Props = {};

const MDimitrukSoftpull = (props: Props) => {
  return (
    <iframe
      title="Soft Credit Pull"
      width="100%"
      height="1200px"
      src="https://secure.isoftpull.com/?l=Adj5w"
    ></iframe>
  );
};

export default MDimitrukSoftpull;
