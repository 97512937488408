import React from "react";
import {Box, HStack, Text, VStack, Image} from "@chakra-ui/react";

import SubHeader from "./SubHeader";
import Star from "../assets/star.svg";
import RightQuote from "../assets/quotation-right-mark.svg";
import LeftQuote from "../assets/quotation-left-mark.svg";
import Quotes from "../assets/quotes.svg";

type Props = {
  loanOfficerName: string;
  reviews: {author: string; review: string}[];
};

const Reviews = ({loanOfficerName, reviews}: Props) => {
  const renderReviews = () => {
    return reviews.map((review, index) => {
      return (
        <Box
          maxW="500px"
          alignSelf={index % 2 === 0 ? "flex-start" : "flex-end"}
        >
          <HStack spacing={0} mb="5px">
            <Image src={Star} alt="star for reviews" maxW="25px" />
            <Image src={Star} alt="star for reviews" maxW="25px" />
            <Image src={Star} alt="star for reviews" maxW="25px" />
            <Image src={Star} alt="star for reviews" maxW="25px" />
            <Image src={Star} alt="star for reviews" maxW="25px" />
            <Text fontWeight="bold"> - {review.author}</Text>
          </HStack>
          <Text>{review.review}</Text>
        </Box>
      );
    });
  };

  return (
    <VStack id="reviews" w="full">
      <SubHeader
        title={`See What Others are Saying About ${loanOfficerName}`}
        icon={Quotes}
      />
      <VStack
        w={["full", "full", "50vw"]}
        justifyContent={["flex-start"]}
        alignItems={["flex-start"]}
        p={["25px"]}
        spacing="25px"
      >
        {renderReviews()}
        <Image
          opacity={0.5}
          zIndex={-10}
          position="absolute"
          right="10%"
          src={LeftQuote}
          alt="quotation mark"
          maxW="125px"
        />
        <Image
          opacity={[0.5, 0.5, 1]}
          zIndex={-10}
          position="absolute"
          bottom="10%"
          left="10%"
          src={RightQuote}
          alt="quotation mark"
          maxW="125px"
        />
      </VStack>
    </VStack>
  );
};

export default Reviews;
