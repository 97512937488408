import React from "react";
import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  ModalFooter,
} from "@chakra-ui/react";

type Props = {
  failureModalOpen: boolean;
  setFailureModalOpen: any;
  error?: string;
};

const SubmissionFailure = ({
  failureModalOpen,
  setFailureModalOpen,
  error,
}: Props) => {
  return (
    <>
      <Modal
        size="4xl"
        isOpen={failureModalOpen}
        onClose={() => setFailureModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submission Cancelled</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack alignItems="flex-start" justifyContent="space-around">
              <Text>Only one submission per IP Address permitted.</Text>
            </HStack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubmissionFailure;
