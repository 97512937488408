import React, {useState} from "react";
import {Routes, Route} from "react-router-dom";
import AdminLogin from "../components/AdminLogin";
import CampaignSubmissions from "../components/CampaignSubmissions";
import AdminHeader from "../components/AdminHeader";
import {Container} from "@chakra-ui/react";

const Admin = () => {
  const [adminModalOpen, setAdminModalOpen] = useState(true);
  const [auth, setAuth] = useState(false);
  return (
    <Container bg="gray.100" minW="100vw" minH="100vh" m={0} p={0}>
      <AdminLogin
        adminModalOpen={adminModalOpen}
        setAdminModalOpen={setAdminModalOpen}
        setAuth={setAuth}
      />

      {auth && (
        <>
          <AdminHeader />
          <Routes>
            <Route path="/submissions" element={<CampaignSubmissions />} />
          </Routes>
        </>
      )}
    </Container>
  );
};

export default Admin;
