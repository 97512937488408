import React from "react";
import {
  Heading,
  Box,
  Text,
  Hide,
  Stack,
  HStack,
  Image,
} from "@chakra-ui/react";

type Props = {
  title: string;
  children?: string;
  icon?: "*.svg";
  adjustmentLeft?: number;
  adjustmentTop?: number;
};

const SubHeader = ({
  title,
  children,
  icon,
  adjustmentLeft,
  adjustmentTop,
}: Props) => {
  return (
    <HStack spacing="0" alignItems="flex-start" w="full">
      <Stack
        direction={["column", "column", "row"]}
        bgColor="#15225d"
        color="#fff"
        p="10px 30px 15px"
        spacing="30px"
        h={["unset", "unset", "100px"]}
        w="full"
        alignItems={["flex-start", "flex-start", "center"]}
      >
        <Heading size="xl">{title}</Heading>
        <Text>{children}</Text>
      </Stack>
      <Hide below="lg">
        <Box
          m="0"
          display="inline-block"
          bg="#d9d9d9"
          h="130px"
          minW="100px"
          clipPath="polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%)"
        >
          <Image
            src={icon}
            alt="icon"
            ml={adjustmentLeft}
            mt={adjustmentTop}
            maxH="6rem"
          />
        </Box>
      </Hide>
    </HStack>
  );
};

export default SubHeader;
