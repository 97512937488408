import React from "react";
import {Center, Divider, Stack, Image, Text, VStack} from "@chakra-ui/react";

import EqualHousing from "../assets/equal_housing.png";

type Props = {
  LoBranding: "*.png";
  LoBrandingMobile: "*.png";
};

const Footer = ({LoBranding, LoBrandingMobile}: Props) => {
  return (
    <Center bg="gray.300" w="100%" py="5">
      <VStack>
        <Image
          mb={"2vh"}
          srcSet={`${LoBranding} 1200w,${LoBrandingMobile} 1000w`}
          sizes={`(max-width: 800px) 1000px,
            1200px`}
        />
        <Divider w={"85%"} />
        <Stack
          px={"1vw"}
          mt={"15vh"}
          flexDirection={["column-reverse", "column-reverse", "row"]}
          justifyContent={["center"]}
          alignItems={["center"]}
        >
          <Image
            w={"100px"}
            px={"1vw"}
            src={EqualHousing}
            alt="Equal Housing Opportunity Logo"
          />
          <Text fontSize={".75rem"} textAlign={["center"]}>
            Data Mortgage, Inc. is an Equal Housing Lender doing business as
            Essex Mortgage. All loan programs, terms, and rates are subject to
            change without notice. Additional terms and conditions may apply.
            Essex Mortgage is not an agency of, nor is it affiliated with the
            federal government or any other entity referenced herein, except
            Essex Mortgage. Essex Mortgage's corporate office is located at 2200
            W. Orangewood Ave. Suite 150 Orange, CA 92868 Tel: 714-935-2581;
            Loans made or arranged pursuant to a California Financing Law
            license. CA Department of Real Estate License #00936013; CA
            Department of Financial Protection and Innovation License #603G833,
            NMLS #70377 . Georgia Residential Mortgage Lender Licensee #23621.
            AZ #0943121, ME #70377, NV #3487, WA #CL-70377. For licensing
            information, go to www.nmlsconsumeraccess.org.
          </Text>
        </Stack>
      </VStack>
    </Center>
  );
};

export default Footer;
