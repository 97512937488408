import React, {useEffect} from "react";

type Props = {};

const NotFound = (props: Props) => {
  useEffect(() => {
    window.location.replace(
      "https://www.essexmortgage.com/find-a-loan-officer-redirected",
    );
  }, []);

  return <div>Not Found</div>;
};

export default NotFound;
