import React from "react";
import { Routes, Route } from "react-router-dom";
import Campaign from "./campaigns/pages/Campaign";
import Admin from "./admin/pages/Admin";
import NotFound from "./components/NotFound";
import { exportedRoutes } from "./routes";
import TCollinsSoftpull from "./softpull/pages/TCollinsSoftpull";
import BRobbinsSoftpull from "./softpull/pages/BRobbinsSoftpull";
import THarrisonSoftpull from "./softpull/pages/THarrisonSoftpull";
import MDimitrukSoftpull from "./softpull/pages/MDimitrukSoftpull";

const App = () => {
  const renderRoutes = () => {
    return exportedRoutes.map((route) => {
      return (
        <Route
          path={`/${route.campaignPrefix}-${route.campaignSuffix}/*`}
          key={`${route.campaignPrefix}-${route.campaignSuffix}`}
          element={
            <Campaign
              loanOfficerName={route.loanOfficerName}
              campaignPrefix={route.campaignPrefix}
              campaignSuffix={route.campaignSuffix}
              surefireId={route.surefireId}
              LoBranding={route.LoBranding}
              LoBrandingMobile={route.LoBrandingMobile}
              simpleNexus={route.simpleNexus}
              softpull={route.softpull}
              statesLicensedIn={route.statesLicensedIn}
              bio={route.bio}
              headshot={route.headshot}
              reviews={route.reviews}
              cell={route.cell}
            />
          }
        />
      );
    });
  };

  return (
    <Routes>
      {renderRoutes()}
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/tcollins-softpull" element={<TCollinsSoftpull />} />
      <Route path="/brobbins-softpull" element={<BRobbinsSoftpull />} />
      <Route path="/tharrison-softpull" element={<THarrisonSoftpull />} />
      <Route path="/mdimitruk-softpull" element={<MDimitrukSoftpull />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
