import React from "react";
import {Box, Stack, Text, VStack, Image} from "@chakra-ui/react";

import SubHeader from "./SubHeader";
import About from "../assets/about_lo.svg";

type Props = {loanOfficerName: string; bio: string; headshot: "*.png"};

const AboutLoanOfficer = ({loanOfficerName, bio, headshot}: Props) => {
  return (
    <Box w="full" bgColor="#fff" p="10vh 0" id="about-loan-officer">
      <SubHeader title={`About ${loanOfficerName}`} icon={About}></SubHeader>
      <Stack
        direction={["column", "column", "row"]}
        alignItems={["center", "center", "flex-start"]}
        justifyContent={["space-around"]}
        mt="25px"
      >
        <Box p="20px" bgColor="#c2c3c9" clipPath="circle(50%)">
          <Image
            fit={"cover"}
            src={headshot}
            borderRadius="full"
            boxSize="200px"
            alt="Loan officer headshot"
            clipPath="circle(50%)"
          />
        </Box>

        <VStack maxW="650px" p="15px">
          {bio.split("\n").map((item, key) => {
            return (
              <Text key={key}>
                {item}
                <br />
                <br />
              </Text>
            );
          })}
        </VStack>
      </Stack>
    </Box>
  );
};

export default AboutLoanOfficer;
