import React from "react";

type Props = {};

const THarrisonSoftpull = (props: Props) => {
  return (
    <iframe
      title="Soft Credit Pull"
      width="100%"
      height="1200px"
      src="https://secure.isoftpull.com/?l=or4E4"
    ></iframe>
  );
};

export default THarrisonSoftpull;
