import React from "react";
import {Box, Stack, Text, VStack, Image} from "@chakra-ui/react";

import TopWork from "../assets/TopWork.png";

import SubHeader from "./SubHeader";
import About from "../assets/about.svg";
import Roland from "../assets/roland.png";
type Props = {};

const AboutEssex = (props: Props) => {
  return (
    <Box w="full" bgColor="#fff" p="10vh 0" id="about-essex">
      <SubHeader title="About Essex Mortgage" icon={About}></SubHeader>
      <Stack
        direction={["column", "column", "row"]}
        alignItems={["center", "center", "flex-start"]}
        justifyContent={["space-around"]}
        mt="25px"
      >
        <Text p="0 25px" maxW="700px">
          Hailing from Essex County, England, Roland Weedon founded Essex
          Mortgage in 1986 with a focus on family and education. Since then,
          Essex Mortgage has never wavered in our determination to provide
          financial literacy to all involved in the home buying process. For
          more than three decades, we have been navigating our partners, our
          clients and their families through the largest single purchase of
          their lifetimes. It is this level of dedication and commitment to
          every transaction that we expect from all of our employees. As more in
          our industry move toward a fully-automated experience, Essex Mortgage
          stands firm on our belief that people make the difference. Still
          family-owned and operated, this faith in people will drive us for
          another thirty years and more because that is who we are.
        </Text>

        <VStack gap="25px" justifyContent={"center"}>
          <Box
            bgColor={"#919191"}
            boxShadow="15px 15px 0px 6px rgba(21,34,93,1);
-webkit-box-shadow: 15px 15px 0px 6px rgba(21,34,93,1);
-moz-box-shadow: 15px 15px 0px 6px rgba(21,34,93,1);"
            w={["80vw", "80vw", "15vw"]}
          >
            <Image src={Roland} w="full" />
          </Box>
          <Image maxW="300px" src={TopWork} alt="Top Workplaces Logo" />
        </VStack>
      </Stack>
    </Box>
  );
};

export default AboutEssex;
