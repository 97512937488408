import React, {useEffect} from "react";
import {
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Image,
  VStack,
} from "@chakra-ui/react";

import {FormSubmissionMeta} from "../types/types";

type Props = {
  successModalOpen: boolean;
  setSuccessModalOpen: any;
  formSubmissionMeta: FormSubmissionMeta;
  loanOfficerName: string;
  values: any;
  LoBrandingMobile: "*.png";
  simpleNexus: string;
  softpull: string;
};

const SubmissionSuccess = ({
  successModalOpen,
  setSuccessModalOpen,
  formSubmissionMeta,
  values,
  loanOfficerName,
  LoBrandingMobile,
  simpleNexus,
  softpull,
}: Props) => {
  useEffect(() => {
    if (values.nextStep === "Apply") {
      window.open(simpleNexus, "_blank");
    } else if (values.nextStep === "Credit Pull") {
      window.open(softpull, "_blank");
    }
  }, [values.nextStep, simpleNexus, softpull]);

  return (
    <>
      <Modal
        size="4xl"
        isOpen={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thank you for your submission</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={5} mb={10}>
            <Center>
              <VStack>
                <Text>
                  We appreciate your interest in Essex Mortgage.{" "}
                  {loanOfficerName} will be in contact with you shortly.
                </Text>
                <Image src={LoBrandingMobile} w="70%" />
              </VStack>
            </Center>
          </ModalBody>
          {/* <ModalBody>
            <HStack alignItems="flex-start" justifyContent="space-around">
              <VStack>
                <Text>Form values:</Text>
                <pre>{JSON.stringify(values, null, 1)}</pre>
              </VStack>
              <VStack>
                <Text>Form submitted at:</Text>
                <pre>{JSON.stringify(formSubmissionMeta, null, 1)}</pre>
              </VStack>
            </HStack>
          </ModalBody> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubmissionSuccess;
