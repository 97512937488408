import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

type Props = {
  adminModalOpen: boolean;
  setAdminModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminLogin = ({adminModalOpen, setAdminModalOpen, setAuth}: Props) => {
  const navigate = useNavigate();

  const [attempted, setAttempted] = useState(false);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");

  const authenticate = async () => {
    try {
      const response = await fetch(
        "https://us-central1-essex-leads.cloudfunctions.net/authenticateAdmin",
        {
          method: "POST",
          body: JSON.stringify({passwordAttempted: password}),
        },
      );
      if (response.status === 200) {
        return true;
      } else if (response.status === 401) {
        setAttempted(true);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const closeModal = async () => {
    if ((await authenticate()) === false) return;

    setAdminModalOpen(false);
    setAuth(true);
    navigate("submissions");
  };

  return (
    <>
      <Modal size="3xl" isOpen={adminModalOpen} onClose={closeModal}>
        <ModalContent>
          <ModalHeader>Admin Login</ModalHeader>
          <ModalBody>
            <VStack>
              <Center>
                <FormControl isRequired isInvalid={attempted}>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      w="20vw"
                      type={show ? "text" : "password"}
                      placeholder="Enter password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShow(!show)}
                      >
                        {show ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>Incorrect password</FormErrorMessage>
                </FormControl>
              </Center>
              <Button onClick={() => closeModal()}>Submit</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminLogin;
